<template>
  <section class="subscriptions">
    <div v-if="data && data.total_downgrade" class="acm">
      <img src="@/assets/images/icons/ic_info_navtt.svg" alt="information" width="21" class="mr-2">
      <span>If you want to decrease team size, you'll need to choose max. {{ data.total_downgrade }} active members <br> in your Community. <router-link :to="{ name: 'Decrease-Member' }">Learn More</router-link></span>
    </div>
    <div class="my-5 h-100">
      <div class="container-fluid h-100">
        <div v-if="loading" class="row text-left text-white h-100 pb-5">
          <div class="col-5">
            <div class="border-separator ml-5 h-100">
              <div class="card__wrapper mx-4">
                <div class="card">
                  <b-skeleton animation="wave" width="50%"></b-skeleton>
                  <div class="mt-3 mb-4">
                    <b-skeleton animation="wave" width="100%"></b-skeleton>
                  </div>

                  <div class="border-payment">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3 mt-4">
                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 h-100 d-flex align-items-start justify-content-center mt-5">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
        <div v-else class="row text-left text-white h-100 pb-5">
          <div class="col-5">
            <div class="border-separator h-100">
              <div class="card__wrapper mx-4">
                <div class="card">
                  <!-- <h3 class="font-weight-normal">{{ data.package_name }} Subscription</h3> -->
                  <h3 class="font-weight-normal">{{ data.swc_request ? 'Top up SWC' : data.package_name }}</h3>
                  <div class="d-flex align-items-center mt-2 mb-4">
                    <img v-if="data.swc_request" src="@/assets/images/sidebar/sb_swc.png" alt="SWC" class="img-fluid">
                    <h1 class="text-bigger"><span v-if="data.price_per_month" class="font-weight-normal">IDR </span>{{ data.swc_request ? data.swc_request : formatPrice(data.price_per_month) }}</h1>
                  </div>

                  <button class="btn p-0 btn--dropdown d-flex align-items-center mb-2" @click="visible = !visible" v-if="data.show_services_package">Service 
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none" class="ml-1 arrow--pc" :class="{'show': visible}">
                        <path d="M1 1L6 6L11 1" stroke="white" stroke-linecap="round"/>
                      </svg>
                    </button>

                    <b-collapse id="collapse-1" v-if="data.show_services_package" v-model="visible" class="mt-2 mb-3">
                          <div class="pr--details row px-3">
                            
                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{allocations.audio_multilingual_duration || "-"}}{{ $t('minutes') }} multi voice</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" v-if="allocations.voice_model_multilingual != 0">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ allocations.voice_model_multilingual || "-" }} {{ $t('voice-clone') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" v-if="allocations.audio_duration != 0">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ allocations.audio_duration || "-" }}{{ $t('minutes') }} mono voice</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ $t('unlimited-ai-script-writer') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ allocations.voice_characters || "-" }} {{ $t('voice-characters') }}</span>
                              </div>

                              <div class="col-6 mb-2 d-flex justify-content-start align-items-start" >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <rect y="0.445312" width="11.1776" height="11.1776" rx="1.11776" fill="#D9D9D9"/>
                                      <path d="M8.94484 2.67578L4.4738 7.7057L2.23828 5.47018" stroke="black" stroke-width="1.11776" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                  <span class="ml-1 text-left" style="font-size: 12px;">{{ $t('unlimited-projects-music-video') }}</span>
                              </div>

                            </div>
                    </b-collapse>

                  <div class="border-payment">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <h4 class="font-weight-normal">{{ $t("bill") }}</h4>
                      <h4><span class="font-weight-normal">IDR </span> {{ formatPrice(data.billed_annualy) }}</h4>
                    </div>

                    <div class="dashed--border mb-3">

                        <div class="d-flex justify-content-between align-items-center" :class="!data.voucher_id && data.discount == 0 && data.discount_percentage == 0 ? 'mb-0' : 'mb-2'">
                            <div class="d-flex align-items-center" v-if="data.voucher_id && data.discount != 0">
                                <h4 class="font-weight-normal mb-0 mr-2">{{$t('discount')}}</h4>
                                <span class="badge--discount">{{ data.voucher_name }}</span>
                                <span class="badge--discount-percen ml-2" v-if="data.discount_percentage">{{ data.discount_percentage }}% Off</span>
                            </div>
                            <div class="d-flex align-items-center" v-else-if="!data.voucher_id && data.discount != 0">
                                <h4 class="font-weight-normal mb-0 mr-2">{{$t('discount')}}</h4>
                                <span class="badge--discount">Referral</span>
                                <span class="badge--discount-percen ml-2" v-if="data.discount_percentage">{{ data.discount_percentage }}% Off</span>
                            </div>
                            <div class="d-flex align-items-center" v-else-if="data.voucher_id && data.discount == 0 && data.discount_percentage == 0">
                                <h4 class="font-weight-normal mb-0 mr-2">Bonus</h4>
                                <span class="badge--discount">{{ data.voucher_name }}</span>
                            </div>
                            <h4><span class="font-weight-normal" v-if="data.discount">- IDR </span> {{ formatPrice(data.discount) }}</h4>
                        </div>

                        <div class="d-flex flex-column align-items-start" :class="!data.voucher_id && data.discount == 0 && data.discount_percentage == 0 ? 'mb-0' : 'mb-4'" v-if="data.discount == 0 && data.discount_percentage == 0">
                            <span v-if="data.audio_multilingual_duration != 0">{{ data.audio_multilingual_duration }}{{ $t("mins") }} Multilingual Audio</span>
                            <span v-if="data.audio_duration != 0">{{ data.audio_duration }}{{ $t("mins") }} Monolingual Audio</span>
                            <span v-if="data.voice_model_multilingual != 0">{{ data.voice_model_multilingual }} Multilingual Voice Clone</span>
                            <span v-if="data.voice_model_monolingual != 0">{{ data.voice_model_monolingual }} Monolingual Voice Clone</span>
                            <span v-if="data.images_generates != 0">{{ data.images_generates }} Images</span>
                            <span v-if="data.swc != 0">{{ data.swc }} SWC</span>
                            <span v-if="data.storage_alloc != 0">{{ data.storage_alloc }}MB Asset Storage</span>
                        </div>
                      </div>

                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h4 class="font-weight-bold">{{ $t('sub-total') }}</h4>
                        <h4><span class="font-weight-normal">IDR </span> {{ data.discount ? formatPrice(sub_total) : sub_total  }}</h4>
                      </div>
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <h4 class="font-weight-normal">{{ $t('tax') }}</h4>
                      <!-- <h4 class="font-weight-normal">Tax<img src="@/assets/images/icons/ic_info.svg" alt="Info" class="img-fluid ml-2" v-b-tooltip.hover.bottomright="'This is tax and services'"></h4> -->
                      <h4><span class="font-weight-normal">IDR </span> {{ formatPrice(data.tax) }}</h4>
                    </div>

                  </div>
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <h4 class="font-weight-normal">TOTAL</h4>
                    <h4 style="font-size: 24px;"><span class="font-weight-normal">IDR </span> {{ formatPrice(data.total) }}</h4>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="card__wrapper h-100 mx-4">
              <div class="card h-100" style="background-color:white;">
                <iframe :src="data.invoice_url" class="w-100 my-3" name="payment" title="Invoice Soca Pro" frameborder="0" ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import $ from "jquery";
import io from "socket.io-client"

import { getInvoices } from '@/services/subscriptions/subscriptions.service'
import {base_url_socket} from '@/config/base_url'
import { getDetailPayment } from "@/services/payment/payment.service.js"

export default {
  data() {
    return {
      data: null,
      loading : false,
      socket : io(base_url_socket),
      visible: false,
      allocations: {},
      loading_detail_payment: false
    };
  },

  mounted() {
    $("section.subscriptions").parent().addClass("mx-2");

    this.getSubscription(this.$route.query.id)
    this.getDetailPayment();
    this.socket.emit('createRoom', this.$route.query.id)

    this.socket.on('paid', () => {
      if(this.$route.query.redirect) {
        this.$router.push({ name : 'Payment-Success', query: { redirect : this.$route.query.redirect}})
      } else {
        this.$router.push({ name : 'Payment-Success'})
      }
    });
    // const redirectPath = this.$route.query.redirect;
  },

  computed: {
    sub_total(){
        return this.data.discount ? this.data.billed_annualy - this.data.discount : this.formatPrice(this.data.billed_annualy)
      }
  },
  methods: {
    getDetailPayment(){
      this.loading_detail_payment = true;
      getDetailPayment(this.$route.query.packageId, this.$route.query.packageBill).then((res) =>{
        if(res.status){
          this.allocations = res.data;
          this.loading_detail_payment = false;
        }
      }).catch((err)=>{
        console.log(err)
        this.loading_detail_payment = false;
      })
    },
    getSubscription(id){
      this.loading = true;
      getInvoices(id)
      .then((response) => {
        if(response.status == true){
          this.data = response.data
          this.loading = false;
        } else {
          this.loading = false;
        }
      })
    },

    formatPrice(price) {
      if(price) {
        return `${price.toLocaleString()}`;
      } else {
        return null
      }
      // return price;
    },
  },

  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>

<style scoped>
.dashed--border{
    border-bottom: 0.1px dashed #383838;
    padding-bottom: 1rem;
  }
  .tax-title{
    font-size: 18px;
  }
.badge--discount{
    color: #6D75F6;
    padding: 2px 11px;
    background-color: rgba(109, 117, 246, 0.2);
    border-radius: 12.5px;
    border: 1px solid #6D75F6;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
  }
  .badge--discount-percen{
    color: #C4C4C4;
    padding: 2px 11px;
    background-color: rgba(196, 196, 196, 0.2);
    border-radius: 12.5px;
    border: 1px solid #C4C4C4;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
  }
.btn--dropdown{
    color: #fff;
    font-size: 18px;
  }
  .subscriptions {
    padding: 90px 80px 40px 80px;
    height: 98vh;
  }

  .text-bigger {
    font-size: 40px;
  }

  .sub-text {
    font-size: 24px !important;
  }

  .border-separator {
    border-right: 0.1px solid #383838;
  }

  .border-payment {
    border-top: 0.1px solid #383838;
    padding-top: 2rem;
    border-bottom: 0.1px dashed #383838;
  }

  iframe {
    height: 100%;
  }

  .acm {
    padding: 10px 30px;
    background: rgba(109, 117, 246, 0.2);
    width: fit-content;
    margin: auto;
  }

  .acm span {
    color: white;
    font-weight: 600;
    font-size: 20px;
  }

  .acm span a {
    color: #6D75F6;
    text-decoration: underline;
  }

  .card__wrapper {
    padding: 2px;
	  background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	  border-radius: 8px;
  }

  .card {
    padding: 32px;
    background-color: #121418;
    border-radius: 6px;
    backdrop-filter: blur(4px);
  }
</style>
