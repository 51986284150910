import axios from "../../config/axios";
import ApiRoute from '../api.route';
import { base_url_general } from "../../config/base_url"

export async function getDetailPayment(id, package_bill) {
    return axios.get(base_url_general + ApiRoute.payment + "detail-package/"+id+"/"+package_bill)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}
export async function searchVoucher(data) {
    return axios.get(base_url_general + ApiRoute.voucher + data.voucher+"/"+data.package_id+"/"+data.package_bill)
      .then(res => {
        const data = res.data
        return data;
      })
      .catch((error) => {
        const data = { status: false, response: error }
        return data;
    })
}